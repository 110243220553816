
import { VirtualRelation } from '@/models/Product';
import ProductDetailsModule from '@/store/modules/ProductDetailsModule';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'VirtualRelationSelector',
    components: {

    }
})
export default class VirtualRelationSelector extends Vue {
    
    get relations(): VirtualRelation[] {
        if (ProductDetailsModule.SELECTED_VARIANT)
            return ProductDetailsModule.SELECTED_VARIANT?.virtualrelations;
        else
            return ProductDetailsModule.PRODUCT?.virtualrelations;
    }

    get activeRelation(): VirtualRelation {
        return ProductDetailsModule.SELECTED_VIRTUAL_RELATION;
    }

    vatDesc(relation: VirtualRelation) {
        if (relation.price.showpricesincvat)
            return this.$t('pricing.inc-vat', ['inc. VAT']);
        else
            return this.$t('pricing.ex-vat', ['ex. VAT']);
    }

    setActive(relation: VirtualRelation)
    {
        ProductDetailsModule.SET_SELECTED_VIRTUAL_RELATION(relation);
    }
}
